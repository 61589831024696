import 'moment/locale/en-gb';
import $ from 'jquery';
import 'jquery-validation';
import nl from "./nl";

let en = {};

en.previous = "Previous";
en.next = "Next";
en.today = "Today";

en.datatables = {
	"lengthMenu": "Show _MENU_ entries",
	"zeroRecords": "No results found",
	"info": "Showing _START_ to _END_ of _TOTAL_ entries",
	"infonoend": "Showing _START_ of _TOTAL_ entries",
	"infonototal": "Showing entry _START_ to _END",
	"infoEmpty": "No results available",
	"infoFiltered": " (filtered from _MAX_ total entries)",
	"infoPostFix": "",
	"search": "Search:",
	"emptyTable": "No data available in table",
	"infoThousands": " ",
	"loadingRecords": "Please wait - Loading...",
	"paginate": {
		"first": "First",
		"last": "Last",
		"next": "Next",
		"previous": "Previous"
	},
	"aria": {
		"sortAscending": ": activate to sort column ascending",
		"sortDescending": ": activate to sort column descending"
	}
};

en.pikadayi18n = {
	previousMonth : 'Previous Month',
	nextMonth     : 'Next Month',
	months        : ['January','February','March','April','May','June','July','August','September','October','November','December'],
	weekdays      : ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'],
	weekdaysShort : ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'],
	firstDay      : 1,
};

en.verwijderen = 'Delete';
en.aanvragen = 'Request';
en.bekijk = 'View';
en.details = 'Details';
en.linkeduser = 'Has an linked myVanheede account';
en.createuser = 'Create an linked myVanheede account';
en.opafroep = 'On request';
en.download = 'Download';
en.update = 'Update';

en.hasnonconform = 'We have registered a non-conformity on this task.';
en.warning1 = 'The current selection has ';
en.warning2 = ' open, therefore the data below can not be viewed as definite.';
en.warning3 = 'The current selection has ';
en.warning4 = ' open tasks, therefore the data below can not be viewed as definite.';

en.no_results_text = 'No results for ';

en.validator = {
	required: "This field is required.",
		remote: "Please fix this field.",
		email: "Please enter a valid email address.",
		url: "Please enter a valid URL.",
		date: "Please enter a valid date.",
		dateISO: "Please enter a valid date (ISO).",
		number: "Please enter a valid number.",
		digits: "Please enter only digits.",
		equalTo: "Please enter the same value again.",
		maxlength: $.validator.format( "Please enter no more than {0} characters." ),
		minlength: $.validator.format( "Please enter at least {0} characters." ),
		rangelength: $.validator.format( "Please enter a value between {0} and {1} characters long." ),
		range: $.validator.format( "Please enter a value between {0} and {1}." ),
		max: $.validator.format( "Please enter a value less than or equal to {0}." ),
		min: $.validator.format( "Please enter a value greater than or equal to {0}." ),
		step: $.validator.format( "Please enter a multiple of {0}." )
}
export default en;