import $ from "jquery";
import nl from "./lang/nl";
import fr from "./lang/fr";
import en from "./lang/en";

let Translator = {};

Translator.translate = (string) => {
    switch ($('html').data('locale')) {
        case 'nl':
            window.i18n = nl;
            break;
        case 'fr':
            window.i18n = fr;
            break;
        case 'en':
            window.i18n = en;
            break;
    }
    return new Function('let returnval = i18n.' + string + ';delete window.i18n; return returnval;')(); //because window. is recipe to disaster
}

export default Translator;