
import $ from "jquery";

let Flashes = {}

/**
 * Flash verbergen
 *
 * @param dit
 * @param e
 */
Flashes.close = function(dit, e) {
    e.stopPropagation();
    $(dit).parent().animate({opacity: 0}, 300, function () {
        $(this).remove();
    });
}

$(document).on('click', '.flash-close', function (event) {
    Flashes.close(this, event);
});

/**
 * Flash toevoegen
 *
 * Mogelijke categorieën:
 * - success
 * - warning
 * - error
 * - info
 *
 * @param category
 * @param message
 * @param important
 */
Flashes.add = function(category, message, important = false) {
    let icon = '';
    switch (category) {
        case 'success': icon = "icon-check-inverted"; break;
        case 'warning': icon = "icon-attention-inverted"; break;
        case 'error': icon = "icon-error-inverted"; break;
        case 'info': icon = "icon-info-inverted"; break;
    }
    if(important) {
        $('.content .flashes').prepend('<div class="flash-notice ' + category + ' animated">' +
            '<div class="flash-icon"><span class="icon ' + icon + '"></span></div>' +
            '<div class="flash-label">' + message + '</div>' +
            '<button type="button" class="flash-close" title=""><span class="icon icon-error-inverted"></span></button>' +
            '</div>');
        var theFlash = $('.content .flashes').children(':first-child');
    } else {
        $('.not-important-flashes .flashes').prepend('<div class="flash-notice ' + category + ' animated">' +
            '<div class="flash-icon"><span class="icon ' + icon + '"></span></div>' +
            '<div class="flash-label">' + message + '</div>' +
            '<button type="button" class="flash-close" title=""><span class="icon icon-error-inverted"></span></button>' +
            '</div>');
        var theFlash = $('.not-important-flashes .flashes').children(':first-child');
    }
    setTimeout(function() {
        theFlash.find('.flash-close'). trigger('click');
    }, 5000)
}

export default Flashes;