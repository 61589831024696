import $ from 'jquery'
import 'moment'
import 'jquery-validation'
import Translator from '../translator'
import Flashes from '../flashes'

$(function () {
  compatibleBrowser()
  initLogin()
})

var cinterval


function initLogin () {
  if ($('form').length) {
    const messages = Translator.translate('validator')
    $.extend($.validator.messages, messages)
    $.validator.setDefaults({
      onkeyup: false,
      ignoreTitle: true,
      rules: {
        'fld-password': { minlength: 8 },
        'number': { number: true },
      },
    })
    $('form.login-form').validate({
      rules: {
        email: {
          required: true,
          email: true,
        },
        password: {
          required: true,
          minlength: 8,
        },
      },
    })
  }
}

function evaluates (statement) {
  try {
    eval(statement)
    return true
  } catch (err) {
    return false
  }
}

function compatibleBrowser () {
  if (!evaluates('let a = 1')) {
    $('.compatiblebrowser').show()
  }
}

