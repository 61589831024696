import 'moment/locale/nl';
import $ from 'jquery';
import 'jquery-validation';

let nl = {};

nl.previous = "Vorige";
nl.next = "Volgende";
nl.today = "Vandaag";

nl.datatables = {
	"lengthMenu": "_MENU_ resultaten weergeven",
	"zeroRecords": "Geen resultaten gevonden",
	"info": "_START_ tot _END_ van _TOTAL_ resultaten",
	"infonoend": "_START_ van _TOTAL_ resultaten",
	"infonototal": "Resultaat van _START_ tot _END_",
	"infoEmpty": "Geen resultaten om weer te geven",
	"infoFiltered": " (gefilterd uit _MAX_ resultaten)",
	"infoPostFix": "",
	"search": "Zoeken:",
	"emptyTable": "Geen resultaten om weer te geven",
	"infoThousands": " ",
	"loadingRecords": "Een moment geduld aub - bezig met laden...",
	"paginate": {
		"first": "Eerste",
		"last": "Laatste",
		"next": "Volgende",
		"previous": "Vorige"
	},
	"aria": {
		"sortAscending": ": activeer om kolom oplopend te sorteren",
		"sortDescending": ": activeer om kolom aflopend te sorteren"
	}
};

nl.pikadayi18n = {
	previousMonth : 'Volgende maand',
	nextMonth     : 'Vorige maand',
	months        : ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'],
	weekdays      : ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'],
	weekdaysShort : ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'],
	firstDay      : 1,
};


nl.verwijderen = 'Verwijderen';
nl.aanvragen = 'Aanvragen';
nl.bekijk = 'Bekijk';
nl.details = 'Details';
nl.linkeduser = 'Heeft een gekoppeld myVanheede account';
nl.createuser = 'Maak een gebruiker aan';
nl.hasnonconform = 'Op deze taak hebben wij een non-conformiteit geregistreerd.';
nl.opafroep = 'Op afroep';
nl.download = 'Download';
nl.update = 'Update';

nl.warning1 = 'Voor de huidige selectie staat nog ';
nl.warning2 = ' open, de onderstaande gegevens kunnen dus nog niet als definitief aanschouwd worden.';
nl.warning3 = 'Voor de huidige selectie staan nog ';
nl.warning4 = ' taken open, de onderstaande gegevens kunnen dus nog niet als definitief aanschouwd worden.';

nl.no_results_text = 'Geen resultaten voor ';

nl.validator = {
	required: "Dit is een verplicht veld.",
	remote: "Controleer dit veld.",
	email: "Vul hier een geldig e-mailadres in.",
	url: "Vul hier een geldige URL in.",
	date: "Vul hier een geldige datum in.",
	dateISO: "Vul hier een geldige datum in (ISO-formaat).",
	number: "Vul hier een geldig getal in.",
	digits: "Vul hier alleen getallen in.",
	creditcard: "Vul hier een geldig creditcardnummer in.",
	equalTo: "Vul hier dezelfde waarde in.",
	extension: "Vul hier een waarde in met een geldige extensie.",
	maxlength: $.validator.format("Vul hier maximaal {0} tekens in."),
	minlength: $.validator.format("Vul hier minimaal {0} tekens in."),
	rangelength: $.validator.format("Vul hier een waarde in van minimaal {0} en maximaal {1} tekens."),
	range: $.validator.format("Vul hier een waarde in van minimaal {0} en maximaal {1}."),
	max: $.validator.format("Vul hier een waarde in kleiner dan of gelijk aan {0}."),
	min: $.validator.format("Vul hier een waarde in groter dan of gelijk aan {0}."),
	step: $.validator.format("Vul hier een veelvoud van {0} in.")
}

nl.select2 =  {
	errorLoading: function () {
		return "De resultaten konden niet worden geladen."
	}, inputTooLong: function (e) {
		return "Gelieve " + (e.input.length - e.maximum) + " karakters te verwijderen"
	}, inputTooShort: function (e) {
		return "Gelieve " + (e.minimum - e.input.length) + " of meer karakters in te voeren"
	}, loadingMore: function () {
		return "Meer resultaten laden…"
	}, maximumSelected: function (e) {
		var n = 1 == e.maximum ? "kan" : "kunnen", r = "Er " + n + " maar " + e.maximum + " item";
		return 1 != e.maximum && (r += "s"), r += " worden geselecteerd"
	}, noResults: function () {
		return "Geen resultaten gevonden…"
	}, searching: function () {
		return "Zoeken…"
	}, removeAllItems: function () {
		return "Verwijder alle items"
	}
}
export default nl;