import 'moment/locale/fr';
import $ from 'jquery';
import 'jquery-validation';
import nl from "./nl";
// import 'jquery-validation/dist/localization/messages_fr';

let fr = {};

fr.previous = "Pr&eacute;c&eacute;dent";
fr.next = "Suivant";
fr.today = "aujourd'hui";

fr.datatables = {
	"search": "Rechercher:",
	"lengthMenu": "Afficher _MENU_ &eacute;l&eacute;ments",
	"info": "Affichage de l'&eacute;l&eacute;ment _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
	"infonoend": "Affichage de l'&eacute;l&eacute;ment _START_ sur _TOTAL_ &eacute;l&eacute;ments",
	"infonototal": "Affichage de l'&eacute;l&eacute;ment _START_ &agrave; _END_",
	"infoEmpty": "Affichage de l'&eacute;l&eacute;ment 0 &agrave; 0 sur 0 &eacute;l&eacute;ments",
	"infoFiltered": "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
	"infoPostFix": "",
	"loadingRecords": "Chargement en cours...",
	"zeroRecords": "Aucun &eacute;l&eacute;ment &agrave; afficher",
	"emptyTable": "Aucune donn&eacute;e disponible dans le tableau",
	"paginate": {
		"first": "Premier",
		"previous": "Pr&eacute;c&eacute;dent",
		"next": "Suivant",
		"last": "Dernier"
	},
	"aria": {
		"sortAscending": ": activer pour trier la colonne par ordre croissant",
		"sortDescending": ": activer pour trier la colonne par ordre d&eacute;croissant"
	}
};

fr.pikadayi18n = {
	previousMonth : 'Le mois dernier',
	nextMonth     : 'Le mois prochain',
	months        : ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
	weekdays      : ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
	weekdaysShort : ['dim.', 'lun.', 'mar.', 'mer.', 'jeu.', 'ven.', 'sam.'],
	firstDay      : 1,
};

fr.verwijderen = 'Supprimer';
fr.aanvragen = 'Demander';
fr.bekijk = 'Consulter';
fr.details = 'Détails';
fr.linkeduser = 'Dispose d’un compte myVanheede couplé';
fr.createuser = 'Créer un compte myVanheede';
fr.hasnonconform = 'Nous avons enregistré une non-conformité sur cette tâche.';
fr.opafroep = 'Sur demande';
fr.download = ' Télécharger';
fr.update = 'Update';

fr.warning1 = '';
fr.warning2 = ' sont encore ouvertes pour la sélection actuelle, les données ci-dessous ne peuvent donc pas encore être considérées comme étant définitives.';
fr.warning3 = '';
fr.warning4 = ' tâches sont encore ouvertes pour la sélection actuelle, les données ci-dessous ne peuvent donc pas encore être considérées comme étant définitives.';

fr.no_results_text = 'Pas de résultats pour ';

fr.validator = {
	required: "Ce champ est obligatoire.",
	remote: "Veuillez corriger ce champ.",
	email: "Veuillez fournir une adresse électronique valide.",
	url: "Veuillez fournir une adresse URL valide.",
	date: "Veuillez fournir une date valide.",
	dateISO: "Veuillez fournir une date valide (ISO).",
	number: "Veuillez fournir un numéro valide.",
	digits: "Veuillez fournir seulement des chiffres.",
	creditcard: "Veuillez fournir un numéro de carte de crédit valide.",
	equalTo: "Veuillez fournir encore la même valeur.",
	notEqualTo: "Veuillez fournir une valeur différente, les valeurs ne doivent pas être identiques.",
	extension: "Veuillez fournir une valeur avec une extension valide.",
	maxlength: $.validator.format( "Veuillez fournir au plus {0} caractères." ),
	minlength: $.validator.format( "Veuillez fournir au moins {0} caractères." ),
	rangelength: $.validator.format( "Veuillez fournir une valeur qui contient entre {0} et {1} caractères." ),
	range: $.validator.format( "Veuillez fournir une valeur entre {0} et {1}." ),
	max: $.validator.format( "Veuillez fournir une valeur inférieure ou égale à {0}." ),
	min: $.validator.format( "Veuillez fournir une valeur supérieure ou égale à {0}." ),
	step: $.validator.format( "Veuillez fournir une valeur multiple de {0}." ),
	maxWords: $.validator.format( "Veuillez fournir au plus {0} mots." ),
	minWords: $.validator.format( "Veuillez fournir au moins {0} mots." ),
	rangeWords: $.validator.format( "Veuillez fournir entre {0} et {1} mots." ),
	letterswithbasicpunc: "Veuillez fournir seulement des lettres et des signes de ponctuation.",
	alphanumeric: "Veuillez fournir seulement des lettres, nombres, espaces et soulignages.",
	lettersonly: "Veuillez fournir seulement des lettres.",
	nowhitespace: "Veuillez ne pas inscrire d'espaces blancs.",
	ziprange: "Veuillez fournir un code postal entre 902xx-xxxx et 905-xx-xxxx.",
	integer: "Veuillez fournir un nombre non décimal qui est positif ou négatif.",
	vinUS: "Veuillez fournir un numéro d'identification du véhicule (VIN).",
	dateITA: "Veuillez fournir une date valide.",
	time: "Veuillez fournir une heure valide entre 00:00 et 23:59.",
	phoneUS: "Veuillez fournir un numéro de téléphone valide.",
	phoneUK: "Veuillez fournir un numéro de téléphone valide.",
	mobileUK: "Veuillez fournir un numéro de téléphone mobile valide.",
	strippedminlength: $.validator.format( "Veuillez fournir au moins {0} caractères." ),
	email2: "Veuillez fournir une adresse électronique valide.",
	url2: "Veuillez fournir une adresse URL valide.",
	creditcardtypes: "Veuillez fournir un numéro de carte de crédit valide.",
	ipv4: "Veuillez fournir une adresse IP v4 valide.",
	ipv6: "Veuillez fournir une adresse IP v6 valide.",
	require_from_group: $.validator.format( "Veuillez fournir au moins {0} de ces champs." ),
	nifES: "Veuillez fournir un numéro NIF valide.",
	nieES: "Veuillez fournir un numéro NIE valide.",
	cifES: "Veuillez fournir un numéro CIF valide.",
	postalCodeCA: "Veuillez fournir un code postal valide."
}
fr.select2 = {
	errorLoading: function () {
		return "Les résultats ne peuvent pas être chargés."
	}, inputTooLong: function (e) {
		var n = e.input.length - e.maximum;
		return "Supprimez " + n + " caractère" + (n > 1 ? "s" : "")
	}, inputTooShort: function (e) {
		var n = e.minimum - e.input.length;
		return "Saisissez au moins " + n + " caractère" + (n > 1 ? "s" : "")
	}, loadingMore: function () {
		return "Chargement de résultats supplémentaires…"
	}, maximumSelected: function (e) {
		return "Vous pouvez seulement sélectionner " + e.maximum + " élément" + (e.maximum > 1 ? "s" : "")
	}, noResults: function () {
		return "Aucun résultat trouvé"
	}, searching: function () {
		return "Recherche en cours…"
	}, removeAllItems: function () {
		return "Supprimer tous les articles"
	}
}

export default fr;